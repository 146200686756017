import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal, {closeModal} from '../../../components/helpers/Modal';
import errorsAction from '../../../store/actions/commun/errorsAction';
import {isMobileOnly} from 'react-device-detect';

import {
  sendConnectionRequest,
  getConnectionStatus,
  getConnectionReceived,
  acceptRequest,
  declineRequest, getUnreadPost,
} from './api/network';
import {getUnreadMessages} from './api/messages';

import {connect, useDispatch} from 'react-redux';
import avatar from '../../../images/avatar.jpg';
import Validators from '../../../components/helpers/Validators';

const SecondaryMenu = ({page, user, targetedUser, errorMessageDisplay, network, message, userNetwork: sendRequestAuthorized}) => {
  let menu;
  const [modal, setModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [status, setStatus] = useState({});
  const [connectionRequest, setConnectionRequest] = useState({});
  const [textMessage, setTextMessage] = useState('');
  const [isConnectedUsers, setIsConnectedUsers] = useState(false);
  const [requestHasText, setRequestHasText] = useState(false);
  const [chatList, setChatList] = useState({});
  const dispatch = useDispatch();
  
  useEffect(() => {
    setInterval(()=> {
      connectionReceived();
    }, 10000);
    setInterval(() => {
      getUnreadPost(user.uid, false, item => {
        dispatch({type: 'UNREAD_POSTS', payload: item.count});
      }, errorMessageDisplay);
      getUnreadMessages(user.uid, setChatList, errorMessageDisplay);
    }, 50000);
    
    getUnreadMessages(user.uid, setChatList, errorMessageDisplay);
    
    connectionReceived();
    getConnectionStatus(targetedUser.uid, user.uid, data =>{
      setStatus(data.entities[0]);
    },
    errorMessageDisplay);
    
    if (!Validators.isEmpty(network)) {
      const {usersConnected} = network.data;
      setIsConnectedUsers(usersConnected);
    }
    
  },[]);
  
  useEffect(() => {
    let total = '';
    if (!Validators.isEmpty(chatList, true)) {
      total = chatList.reduce((accumulator, current) => accumulator + current.unread.count, 0);
      dispatch({type: 'UNREAD_MESSAGE', payload: total});
    }
  }, [chatList, message.unread.count]);
  
  useEffect(() => {
    removeClassDom();
  });
  
  const handleSendRequest = e => {
    const {id} = e.target;
    if (id === 'send') {
      e.preventDefault();
      setModal(true);
    }
    if (id === 'group_add') {
      setRequestModal(true);
    }
  };
  
  const close = id => {
    closeModal(id);
    setModal(false);
    setRequestModal(false);
  };
  
  const itsMe = () => user.uid === parseInt(targetedUser.uid);
  const connectionReceived = () => {
    if (itsMe() || sendRequestAuthorized.sendRequestAuthorized) {
      getConnectionReceived(targetedUser.uid, 'pending', data => {
        setConnectionRequest(data.entities);
      }, errorMessageDisplay );
    }
  };
  //@todo work around. Need to handle class active from router.
  const removeClassDom = () =>  {
    let path = window.location.pathname;
    if (path !== '/network') {
      let element = document.getElementById('mode_edit');
      element.classList.remove('active');
    }

  };
  
  const sendConnectionRequestToUser = () => {
    if (!status) {
      sendConnectionRequest(targetedUser.uid, textMessage, data => {
        setStatus(data);
      }, errorMessageDisplay );
    }
    close('send');
  };
  
  const acceptRequestHandler = cid => {
    acceptRequest(cid, () => {
      connectionReceived();
    }, errorMessageDisplay);
  };
  
  const declineRequestHandler = cid => {
    declineRequest(cid, () => {
      connectionReceived();
    }, errorMessageDisplay);
  };
  
  const handleTextArea = e => {
    const {value} = e.target;
    if (value) {
      setRequestHasText(true);
    } else {
      setRequestHasText(false);
    }
    setTextMessage(value);
  };
  
  if (page === 'userDetail') {
    let classes = '';
    let sentText = 'Send request';
    if (status && (status.fieldStatus === 'pending' || status.status)) {
      sentText = 'Request sent';
      classes = 'disabled';
    }
    if (status && (status.fieldStatus === 'declined')) {
      sentText = 'Request declined';
      classes = 'disabled';
    }
    if (!sendRequestAuthorized.sendRequestAuthorized && !isConnectedUsers ) {
      sentText = 'Send request blocked';
      classes = 'disabled';
    }
    if (status && (status.fieldStatus === 'approved') || itsMe()) {
      classes = 'hide';
    }
  
    menu = [
      {name: [sentText], link: '#send', icon: 'send', classes},
      {name: 'Posted in my profile', link: '/network', icon: 'mode_edit'},
      {name: 'Profile matching', link: `/network/matching/${targetedUser.uid}`, icon: 'network_check'},
      {name: 'New connections', link: '/network/search', icon: 'add'},
      {name: 'connection request', link: '#request', icon: 'group_add'},
      {name: 'Read my messages', link: '/network/messages', icon: 'message'},
    ];
  }
  else {
    menu = [
      {name: 'Posted in my profile', link: '/network', icon: 'mode_edit'},
      {name: 'My connections', link: '/network/connections', icon: 'group'},
      {name: 'New connections', link: '/network/search', icon: 'add'},
      {name: 'connection request', link: '#request', icon: 'group_add'},
      {name: 'Read my messages', link: '/network/messages', icon: 'message'},
      
    ];
  }
  
  const content = <div className="row">
    <div className="close-modal"><button onClick={() => close('send')}><i className="material-icons">highlight_off</i></button></div>
    <div className="col l12">
      <h3 className="center thin">Request for connection to {targetedUser.firstName} {targetedUser.lastName}</h3>
    </div>
    <div className="col m2 s3">
      <img alt={'miim avatar'} className="circle responsive-img" src={targetedUser.avatar ? targetedUser.avatar : avatar}/>
    </div>
    <div className="col m10 s9">
      <textarea required value={textMessage} onChange={handleTextArea} placeholder="Add your message..." id="textarea1" className="materialize-textarea" />
      <p className="center"><button onClick={sendConnectionRequestToUser} className={`${!requestHasText ? 'disabled' : ''} btn Textbtn dark-blue`}>Submit request</button> </p>
    </div>
  </div>;
  
  const requestReceived = () => {
    return <div className="user-list">
      <Modal
        id="group_add_"
        className={['bottom-sheet']}
        content={(
        <>
          <ul className="collection user-request">
            {
              !Validators.isEmpty(connectionRequest, true) ? connectionRequest.map(item => (
                <li className={`collection-item ${!isMobileOnly? 'avatar' : 'mobile'}`} key={item.name}>
                  <div className="row">
                    <div className="col m2 s12">
                      <img src={!item.userId.entity.userPicture ? avatar : item.userId.entity.userPicture.url } alt="" className="circle" />
                      <span className="title">{item.userId.entity.fieldMiimUserFname} {item.userId.entity.fieldMiimUserLname}</span>
                    </div>
                    <div className="col m7 s12">
                      {item.fieldMessage}
                    </div>
                    <div className="col m3 s12 request-secondary-content">
                      <button onClick={()=>acceptRequestHandler(item.uuid)} className="btn dark-blue">Accept</button>
                      <button onClick={()=>declineRequestHandler(item.uuid)} className="btn red">Decline</button>
                    </div>
                  </div>
                </li>
              )) : <p className="center">No connection request received</p>
            }
          </ul>
        </>
        )}
        footer={(
          <button
            className={'wave-effect waves-green btn-flat'}
            onClick={() => close('group_add')}
          >
          Close
          </button>
        )}
      />
    </div>;
  };
  return(
   
    <div className="btn-group">
      {modal ?  <Modal id="send" {...{content}} /> : ''}
      {requestModal ? requestReceived() : ''}
      {
        menu
          .map(item => {
            if (item.icon === 'send' && isConnectedUsers || item.icon === 'network_check' && !isConnectedUsers) {
              return;
            }
            if (item.icon === 'group_add' && !Validators.isEmpty(connectionRequest, true) && !itsMe()) {
              return;
            }
            return <NavLink
              id={item.icon} onClick={handleSendRequest}
              key={item.name} to={item.link}
              className={`${item.classes} waves-effect waves-light btn full dark-blue modal-trigger ${!connectionRequest.length && item.icon === 'group_add' ? 'hide' : ''}`}>
              {item.name} <i className="material-icons right">{item.icon}</i>
              {connectionRequest.length > 0 && item.icon === 'group_add' ? <span className="badge">{connectionRequest.length}</span> : '' }
              {message.posts && item.icon === 'mode_edit' ? <span className="badge">{message.posts}</span> : '' }
              {message.unread && item.icon === 'message' ? <span className="badge">{message.unread}</span> : '' }

            </NavLink>;
          })
      }
    </div>
  );
};
SecondaryMenu.propTypes = {
  page: PropTypes.string,
  user: PropTypes.object,
  targetedUser: PropTypes.object,
  errorMessageDisplay: PropTypes.func,
  network: PropTypes.object,
  message: PropTypes.object,
  profile: PropTypes.object,
  userNetwork: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  ...errorsAction,
})(SecondaryMenu);
  
