import {queuedQuery} from '../../../../store/actions/commun/query';
import {config} from '../../../../config/Config';
import Validators from '../../../../components/helpers/Validators';

const queryVersion = 'miim_network';

export const startChat = (uid, callback, errorHandler) => {
  const id = 'start-chat';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables: {
          input: {
            targetUser: uid,
          },
        },
      },
    },
    then: ({data: {data: {createChat}}}) => {
      const {errors, violations, chat} = createChat;
      if (violations.length || errors.length) {
        errorHandler('An error occurred. Please contact the helpdesk.');
      }
      else {
        const {target: {user}, ...chatInfo} = chat;
        callback({
          ...chatInfo,
          user,
        });
      }
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getUserList = (uid, callback, errorHandler) => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/miim/network/user-list?range=0`,
      method: 'get',
    },
    then: ({data}) => {
      callback(data);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getChat = (userId, callback, errorHandler) => {
  const variables = JSON.stringify({
    userId,
  });

  const id = 'chats';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql?queryId=${queryVersion}:${id}&variables=${variables}`,
      method: 'get',
    },
    then: ({data: {data: {listChats: {chats}}}}) => {
      const sortedChat = chats.sort((a, b) => {
        if (Validators.isEmpty(b.last.list, true) || Validators.isEmpty(a.last.list, true)) {
          return 0;
        }
        return  b.last.list[0].created - a.last.list[0].created;
      });
      callback(sortedChat);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getUnreadMessages = (userId, callback, errorHandler) => {
  const variables = JSON.stringify({
    userId,
  });
  
  const id = 'unread-messages';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql?queryId=${queryVersion}:${id}&variables=${variables}`,
      method: 'get',
    },
    then: ({data: {data: {listChats: {chats}}}}) => {
      callback(chats);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getMessages = (chatId, callback, errorHandler) => {
  const variables = JSON.stringify({
    chatId,
  });

  const id = 'messages';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql?queryId=${queryVersion}:${id}&variables=${variables}`,
      method: 'get',
    },
    then: ({data: {data: {messages: {list}}}}) => {
      callback(list);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const markAsRead = (chatId, callback, errorHandler) => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/miim/network/mark-as-read/${chatId}`,
      method: 'patch',
      data: {},
    },
    then: () => {
      callback();
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const sendMessage = (chat, body, callback, errorHandler) => {
  const variables = {
    input: {
      chat,
      body,
    },
  };

  const id = 'send';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: () => {
      callback();
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};
