const initState = {
  toggled: {},
};
const checkReducer = (state = initState, action) => {
  const newState = {
    ...state,
  };
  if (action.type === 'CHECK_TOGGLE') {
    newState.toggled = {
      ...newState.toggled,
      ...action.payload,
    };
  }
  return newState;
};

export default checkReducer;
