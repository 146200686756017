import {queuedQuery} from '../../../../../store/actions/commun/query';
import {config} from '../../../../../config/Config';

export const matching = (uid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/match/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: ({data}) => {
      callback(data);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};
