const initState = {
  message: null,
  show: true,
  unread: '',
};
const messageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'DISPLAY_MESSAGE_SUCCESS':
      return {
        ...state,
        message: action.payload,
      };

    case 'REMOVE_MESSAGE':
      localStorage.removeItem('message');
      return {
        ...state,
        message: null,
      };

    case 'SHOW_NAV':
      return {
        ...state,
        show: action.payload,
      };
  
    case 'UNREAD_POSTS':
      return {
        ...state,
        posts: action.payload,
      };
    case 'UNREAD_MESSAGE':
      return {
        ...state,
        unread: action.payload,
      };

    default:
      return state;
  }
};

export default messageReducer;
