const initState = {};
const networkReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NETWORK_CONNECTION_STATUS':
      return {
        ...state,
        data: action.payload,
      };
    case 'NETWORK_CONNECTION_RECEIVED':
      return {
        ...state,
        data: action.payload,
      };
    case 'USERS_CONNECTED':
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default networkReducer;
