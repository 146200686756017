const initState = {
  data: '',
};
const nodeReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NODE_LOAD_SUCCESS':
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default nodeReducer;
