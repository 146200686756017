const initState = {
  cognition: [],
  behavior: [],
  toggle: [],
};
const checkboxReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CHECKBOX_CHECKED':
      if (action.checked) {
        if (action.options.includes(action.value)) {
          return {
            ...state,
            [action.name]: [
              action.value,
            ],
          };
        } else {
          let newState = {
            ...state,
          };
          newState[action.name].push(action.value);
          newState[action.name] = newState[action.name].filter(item => !action.options.includes(item));
          return newState;
        }
      } else {
        return {
          ...state,
          [action.name]: state[action.name].filter(item => item !== action.value),
        };
      }
    case 'CHECKBOX_RESET':
      return {
        ...state,
        cognition: [],
        behavior: [],
      };
    default:
      return state;
  }
};

export default checkboxReducer;
