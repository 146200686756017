import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import avatar from '../../../images/avatar.jpg';
import {isMobileOnly} from 'react-device-detect';
import SecondaryMenu from './secondaryMenu';
import {getConnexion, getConnexionRequest, getKnack} from './api/network';
import Helpers from '../../../components/helpers/Helpers';

const Network = ({user, type}) => {
  const [connexionRequest, setConnexionRequestCount] = useState(0);
  const [connexions, setConnexionCount] = useState(0);
  const [knack, setKnack] = useState(Helpers.getSmallLoader('lds-ring'));
  
  useEffect(()=> {
    getConnexionRequest(user.uid, item => {
      setConnexionRequestCount(item);
    }, () => {}
    );
    getConnexion(user.uid, item => {
      setConnexionCount(item);
    }, () => {}
    );
  
    getKnack(user.uid, item => {
      setKnack(item);
    }, () => {}
    );
    
  }, []);
  
  const follow = [
    {label: 'Followers', amount: connexions},
    {label: 'Following', amount: connexionRequest},
  ];
  
  const getSideBlock = () => {
    return (
      <div className="col up_left s12 l3 m12">
        <div className="card box_like mb_large">
          <div className="row valign-wrapper">
            <div className="col s6 m4 l12 valign center-block">
              <div className="card-image waves-effect waves-block waves-light">
                <div id="profile-image" role="button">
                  <img alt={'miim avatar'} className="activator" src={user.avatar ? user.avatar : avatar} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="card-title">
              <h5 className="center">{user.firstName} {user.lastName}</h5>
            </div>
            <div className="seeking-message">
              <div className="center">{knack }</div>
            </div>
            <div className="divider" />
            {
              follow
                .map(item => (
                  <div key={item.label} className="row">
                    <div className="col s6 bold">{item.label}</div>
                    <div className="col s6 right-align">{item.amount ? item.amount : Helpers.getSmallLoader('lds-ring')}</div>
                  </div>
                ))
            }
          </div>
        </div>
        <SecondaryMenu page={type} targetedUser={user} />
      </div>
    );
  };
  return type && type === 'chat' && isMobileOnly ? '' : getSideBlock();
};

Network.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  knack: PropTypes.string ,
};

export default Network;

