const initState = {};
const registrationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'REGISTRATION_SUCCESS':
      return {
        ...state,
        data: action.payload,
        email: action.payload.name[0].value,
      };
    case 'REGISTRATION_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'REMOVE_ERROR_MESSAGE':
      return {
        ...state,
        data: null,
        error: null,
      };

    default:
      return state;
  }
};

export default registrationReducer;
