import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Helpers from '../../components/helpers/Helpers';
import {Link} from 'react-router-dom';

class ErrorPage extends Component {

  componentDidMount() {
    let navBar = document.querySelector('.navbar-fixed');
    navBar.className = 'navbar-fixed';
  }

  componentDidUpdate(prevProps) {
    let navBar = document.querySelector('.navbar-fixed');
    navBar.className = 'navbar-fixed';
  }

  renderPage = () => {
    const {errorsStatus} = this.props;
    return <div className={'section top_banner no-pad-bot" id="index-banner'}>
      <div className={'row'}>
        <div className={'container'}>
          <div className={'col s12'}>
            <div className={'row'}>
              <div className={'col header_large center s12'}>
                <h1
                  className={'error_page_title'}
                > {errorsStatus.status ? Helpers.getErrorStatus(errorsStatus.status) : 'Page not found'} </h1>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col center s12'}>
                <h1 className={'error_page_number'}>{errorsStatus.status ? errorsStatus.status : '404'} </h1>
              </div>
            </div>
            <div className={'row center'}>
              <Link to={'/'} className={'btn-large waves-effect waves-light'}>Go Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  render() {
    return (
      this.renderPage()
    );
  }
}

ErrorPage.propTypes = {
  errorsStatus: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(ErrorPage);

