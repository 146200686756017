import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore, applyMiddleware} from 'redux';
import rootReducer from './store/reducers/rootReducer';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import Helpers from './components/helpers/Helpers';
import {config} from './config/Config';
import {sessionTimeOut} from './store/actions/commun/sessionTimeOut';
import {loadCookie, saveCookie} from './cookiesStorage';
import Cookies from 'js-cookie';

Sentry.init({
  dsn: window._env_.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: window._env_.MIIM_VERSION,
  environment: window._env_.SENTRY_ENVIRONMENT,
});

if (!Helpers.cookiesEnabled()) {
  alert('MiiM application needs cookies enabled to operate correctly, please enable cookies in your browser.');
}

const cookieState = loadCookie();
const store = createStore(rootReducer, cookieState, applyMiddleware(thunk));
store.subscribe(() => {
  saveCookie('state', {
    auth: store.getState().auth,
    message: store.getState().message,
    errorMessage: store.getState().errorMessage,
  });
});

let refreshed = false;

axios.interceptors.response.use(function (response) {
  if (window._env_.MIIM_VERSION !== response.headers['x-miim-version']) {
    if (!refreshed) {
      store.dispatch({type: 'NEW_APP_VERSION'});
      refreshed = true;
    }
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.request.use(
  async requestConfig => {
    const expired = sessionTimeOut();
    if (!requestConfig.noRefresh && expired) {
      const rememberUser = localStorage.getItem('rememberUser');
      await axios({
        method: 'post',
        url: `${config.BASE_PATH}/oauth/token`,
        data: Helpers.refreshToken(Helpers.getRefreshToken()),
        contentType: 'multipart/form-data',
        noBearer: true,
        noRefresh: true,
      }).then(res => {
        const options = rememberUser ? {expires: 14} : {};
        Cookies.set('oauth', res.data, { ...options, sameSite: 'Lax' });
        localStorage.setItem('lastRequestSession', (new Date()).getTime());
      }).catch(error => {
        if (error.response.data.hint === 'Token has expired') {
          store.dispatch({
            type: 'LOGIN_ERROR',
          });
          alert('session expired');
          window.location = '/login';
        }
      });
    }
    if (!requestConfig.noBearer && Helpers.getAccessToken() && !requestConfig.headers['Authorization']) {
      requestConfig.headers['Authorization'] = Helpers.getAccessToken();
    }
    if (requestConfig.contentType) {
      requestConfig.headers['Content-Type'] = requestConfig.contentType;
    }

    return requestConfig;
  },
  error => {
    Promise.reject(error);
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));
serviceWorker.register();
