const errorsAction = {
  errorMessageDisplay: (message) => dispatch => {
    dispatch(
      {
        type: 'DATA_FAILURE',
        payload: message,
      }
    );
  },
  errorMessageRemove: () => dispatch => {
    dispatch(
      {
        type: 'REMOVE_ERROR_MESSAGE',
      }
    );
  },
  errorReset: () => dispatch => {
    dispatch(
      {
        type: 'ERROR_RESET',
      }
    );
  },
  errorStatus: status => dispatch => {
    dispatch({
      type: `ERROR_${status}`,
      payload: status,
    });
  },
};
export default errorsAction;
