const initState = {
  newVersion: false,
};
const newAppVersionReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NEW_APP_VERSION':
      return {
        ...state,
        newVersion: true,
      };
    default:
      return state;
  }
};

export default newAppVersionReducer;
