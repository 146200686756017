import Helpers from '../../../components/helpers/Helpers';

export const sessionTimeOut = () => {
  const expirationTime = Helpers.getTokenExpiration();
  const lastRequest = localStorage.getItem('lastRequestSession');
  const now = new Date();
  if (lastRequest) {
    const timeDiff = lastRequest ? ((now.getTime() - lastRequest) / 1000) : null;
    if ((!timeDiff) || timeDiff >= expirationTime) {
      return true;
    }
  }
  return false;
};
