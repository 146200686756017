import React, {Component} from 'react';
import {Bar, HorizontalBar, Doughnut} from 'react-chartjs-2';
import {isMobileOnly} from 'react-device-detect';

import PropTypes from 'prop-types';

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: props.chartLabels,
      values: props.values,
      label: props.label,
      type: props.type,
      size: props.size,
    };
  }
  getChartOptions = () => {
    const {labels, values, label} = this.state;
    return {
      labels: labels,
      datasets: [
        {
          label: label,
          backgroundColor: '#AB2171',
          borderColor: '#AB2171',
          borderWidth: 0,
          hoverBackgroundColor: '#26A69A',
          hoverBorderColor: '#26A69A',
          data: values,
        },
      ],
    };
  };

  layoutOptions = () => {
    return {
      maintainAspectRatio: true,
      redraw: true,
      scales: {
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
          },
        }],
        yAxes: [{
          gridLines: {
            drawOnChartArea: false,
          },
        }],
      },
    };
  }
  
   getChartHeight = () => {
     const {size} = this.props;
     if (isMobileOnly) {
       return 600;
     }
     else if (size) {
       return  size;
     }
     return 300;
   }

   render() {
     const {type} = this.props;
     if (type === 'HorizontalBar') {
       return <HorizontalBar
         data={this.getChartOptions()}
         height={this.getChartHeight()}
         options={this.layoutOptions()}
       />;
     }
     if (type === 'Bar'){
       return <Bar
         data={this.getChartOptions()}
         height={130}
         options={this.layoutOptions()}
       />;
     }
     if (type === 'Doughnut') {
       return <Doughnut
         data={this.getChartOptions()}
         options={this.layoutOptions()}
       />;
     }

   }
}

Chart.propTypes = {
  chartLabels: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  getChartHeight: PropTypes.func,
  size: PropTypes.number,
};
export default Chart;
