const inputAction = {
  inputChange: event => dispatch => {
    const {name, value} = event.target;
    dispatch({
      type: 'INPUT_CHANGED',
      payload: {
        [name]: value,
      },
    });
  },
};
export default inputAction;
