const initState = {
  profile: true,
  data: false,
  status: null,
};
const miimProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case 'MIIM_PROFILE_SUCCESS':
      return {
        ...state,
        data: action.payload,
        profile: true,
      };

    case 'MIIM_PROFILE_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'MIIM_PROFILE_FAILURE':
      return {
        ...state,
        profile: false,
        status: action.payload,
      };

    default:
      return state;
  }
};

export default miimProfileReducer;
