import React, {Component} from 'react';
import Input from '../../components/Input';
import ScrollToTop from '../../components/helpers/ScrollToTop';
import connect from 'react-redux/es/connect/connect';
import inputAction from '../../components/store/actions/inputAction';
import PropTypes from 'prop-types';
import {resetPasswordAction} from '../../store/actions/accountAction';
import Validators from '../../components/helpers/Validators';
import errorsAction from '../../store/actions/commun/errorsAction';

class ResetPassword extends Component {
  state = {
    type: 'password',
    formHasErrors: false,
  };
  showHideNewPassword = (e) => {
    e.preventDefault();
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };
  validateInput = (e) => {
    let {value, name} = e.target;
    let error = Validators.isValidPassword(value);
    this.setState({
      [name]: error,
      formHasErrors: !!error,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {password, repeatPassword, resetPasswordAction, match, history, errorMessageDisplay} = this.props;
    if (password === repeatPassword && !this.state.formHasErrors) {
      resetPasswordAction(password, match.params.reset_hash, history);
    }
    if (password !== repeatPassword && this.state.password.length < 1) {
      errorMessageDisplay('Password values must be identical.');
    }
  };

  render = () => {
    const {inputChange} = this.props;

    return (
      <>
        <ScrollToTop />
        <div className="section login no-pad-bot" id="index-banner">
          <div className="secondary_pages_top"/>
          <div className="container">
            <div className="row">
              <div className="col header_large">
                <h1 className="header page_title"><i className="fas fa-unlock-alt"/> Reset password</h1>
              </div>
            </div>
            <div className="row box">
              <form className="col s12" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="input-field col s12">
                    <Input
                      type={this.state.type}
                      name={'password'}
                      title={'New password'}
                      onChange={inputChange}
                      onBlur={this.validateInput}
                      className={this.state.formHasErrors ? 'invalid' : ''}
                      required
                    />
                    <span className="toggle-password" onClick={this.showHideNewPassword}>
                      <i className="material-icons">{this.state.type === 'password' ? 'visibility_off' : 'visibility'}</i>
                    </span>
                    <span className="errorMessage">{this.state.password}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <Input
                      type={this.state.type}
                      name={'repeatPassword'}
                      title={'Repeat password'}
                      className={this.state.formHasErrors ? 'invalid' : ''}
                      onChange={inputChange}
                      onBlur={this.validateInput}
                      required
                    />
                    <span className="toggle-password" onClick={this.showHideNewPassword}>
                      <i className="material-icons">{this.state.type === 'password' ? 'visibility_off' : 'visibility'}</i>
                    </span>
                    <span className="errorMessage">{this.state.repeatPassword}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <button id="submit-button" className="btn-large waves-effect waves-light right submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };
}

ResetPassword.propTypes = {
  inputChange: PropTypes.func,
  password: PropTypes.string,
  repeatPassword: PropTypes.string,
  resetPasswordAction: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  errorMessageDisplay: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    password: state.inputChange.changed.password,
    repeatPassword: state.inputChange.changed.repeatPassword,
  };
};

export default connect(mapStateToProps, {...inputAction, resetPasswordAction, ...errorsAction})(ResetPassword);

