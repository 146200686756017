import Helpers from '../../components/helpers/Helpers';
import Cookies from 'js-cookie';

const initState = {};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        ...action.payload,
      };

    case 'USER_ID':
      return {
        ...state,
        isAuthenticated: true,
        current_user: {
          uid: action.payload,
        },
      };

    case 'LOGIN_CHANGE':
      return {
        ...state,
        [action.name]: action.value,
      };

    case 'LOGIN_ERROR':
      localStorage.clear();
      Cookies.remove('state', { path: '' });
      Cookies.remove('oauth', { path: '' });
      Helpers.stateClassesUpdate();
      return {};

    default:
      return state;
  }
};

export default authReducer;
