const initState = {
  feeling: [],
  evolution: [],
};
const rangeReducer = (state = initState, action) => {
  switch (action.type) {
    case 'RANGE_CHANGE':
      return {
        ...state,
        [action.id]: [
          action.value,
        ],
      };
    case 'RANGE_RESET':
      return {
        ...state,
        feeling: [],
        evolution: [],
      };

    default:
      return state;
  }
};

export default rangeReducer;
