/**
 * Validators.
 *
 * @type {{isEmpty: (function(*=): boolean)}}
 */

const Validators = {
  /**
   * Check if is empty value.
   *
   * @param value
   *  The value to check.
   *
   * @param status
   *  The output status. (true or false)
   *
   * @returns {string}
   */
  isEmpty: (value, status = false) => {
    let error = value === null
      || value === undefined
      || (typeof value === 'string' && value.length === 0)
      || (typeof value === 'number' && (isNaN(value) || value === 0))
      || (value instanceof Array && value.length === 0)
      || (typeof value === 'object' && Object.keys(value).length === 0);
    if (error && !status) return 'This field is required.';
    return error && status;
  },

  /**
   * Check Email.
   *
   * @param value
   *  The email value to check.
   *
   * @returns {string}
   */
  isValidEmail: value => {
    const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let validEmail = emailRegex.test(value);
    if (!validEmail) return 'Invalid email address.';
    return '';
  },

  /**
   * Date validation.
   *
   * @param value
   *  The date value to validate.
   *
   * @returns {string}
   */
  isValidDate: value => {
    let myDateStr = new Date(value);
    let validDate = !isNaN(myDateStr.getMonth());
    if (!validDate) return 'Invalid date.';
    return '';
  },
  /**
   * Check password.
   *
   * @param value
   *  Password value.
   *
   * @returns {string}
   */
  isValidPassword: value => {
    const passwordIsStrong = /^[a-zA-Z0-9\S]{8,45}$/;
    let validPassword = passwordIsStrong.test(value)
      && /[A-Z]/.test(value)
      && /[a-z]/.test(value)
      && /[0-9]/.test(value);
    if (!validPassword) return 'Password must contain at least 8 characters, 1 number, 1 uppercase and 1 lowercase.';
    return '';
  },

  isMaxLength: (value, maxLength) => {
    let error = value.length < maxLength;
    if (error) return `Minimum ${maxLength} characters are required.`;
    return '';
  },

  /**
   * Check if fields are identical.
   *
   * @param originalValue
   *  Original string.
   *
   * @param value
   *  The value to compare.
   *
   * @param name
   *   Field name.
   *
   * @returns {string}
   */
  isIdentical: (originalValue, value) => {
    let isNotIdentical = originalValue !== value;
    if (isNotIdentical) return 'Value must be identical.';
    return '';
  },
  
  addZero: i => {
    if (i < 10) {
      i = `0${  i}`;
    }
    return i;
  },
};

export default Validators;
