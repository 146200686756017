import Cookies from 'js-cookie';

export const loadCookie = () => {
  try {
    const serializedState = Cookies.get('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveCookie = (name, value, options) => {
  options = localStorage.getItem('rememberUser') ? {expires: 14} : {};
  try {
    const serializedValue = JSON.stringify(value);
    Cookies.set(name, serializedValue, { ...options, sameSite: 'Lax' });
  } catch (err) {
    // Errors.
  }
};
