import ApolloClient from 'apollo-boost';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createBrowserHistory} from 'history';

// History.
export const history = createBrowserHistory();

export var config = {
  BASE_PATH: window._env_.REACT_APP_API_URL,
};

export var graphQl = {
  client: new ApolloClient({
    uri: `${config.BASE_PATH}/graphql`,
    cache: new InMemoryCache(),
  }),
};
