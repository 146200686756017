const initState = {};
const profileStoreReducer = (state = initState, action) => {

  switch (action.type) {
    case 'GRAPHQL_STORE':
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default profileStoreReducer;
