const initState = {
  file: {},
};
const FileUploadReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FILE_UPLOADED_SUCCESS':
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};

export default FileUploadReducer;
