const initState = {};
const userDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NETWORK_CONNECTION':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userDetailsReducer;

