const initState = {};
const errorsReducer = (state = initState, action) => {
  switch (action.type) {
    case `ERROR_${action.payload}`:
      return {
        ...state,
        status: action.payload === undefined ? 404 : action.payload,
      };
    case 'ERROR_RESET':
      return {};
    default:
      return state;
  }
};

export default errorsReducer;
