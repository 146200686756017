import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Helpers from './helpers/Helpers';

class Input extends Component {

  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    onSelect: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
  };

  componentDidMount = () => {
    const {type, onSelect, className, name} = this.props;
    if (type === 'text' && className && className.indexOf('datepicker') !== -1) {
      var elem = document.getElementById(name);
      window.M.Datepicker.init(elem, {
        onSelect: onSelect,
        yearRange: [1900, Helpers.getBirthDate(15).getFullYear()],
        changeMonth: false,
        changeYear: true,
        showClearBtn: true,
        defaultDate: Helpers.getBirthDate(40),
      });
    }
  };

  render() {
    const {props} = this;
    return (
      <>
        <input
          id={props.name}
          {...props} />
        <label className={props.active} htmlFor={props.name}>{props.title}</label>
      </>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,

};

export default Input;
