import {combineReducers} from 'redux';
import registrationReducer from './registrationReducer';
import authReducer from './authReducer';
import accountReducer from './accountReducer';
import profileReducer from './profileReducer';
import errorMessageReducer from './commun/errorMessageReducer';
import fieldsInformationReducer from './fieldsInformationReducer';
import inputChangeReducer from './commun/inputChangeReducer';
import loadingReducer from './commun/loadingReducer';
import messageReducer from './commun/messageReducer';
import quizReducer from './quizReducer';
import checkboxReducer from './commun/checkboxReducer';
import rangeReducer from './commun/rangeReducer';
import miimProfileReducer from './miimProfileReducer';
import errorsReducer from './commun/errorsReducer';
import nodeReducer from './nodeReducer';
import FileUploadReducer from './commun/FileUploadReducer';
import profileStoreReducer from './profileStoreReducer';
import checkReducer from '../../components/store/reducers/checkReducer';
import inputReducer from '../../components/store/reducers/inputReducer';
import spthinxReducer from './spthinxReducer';
import networkReducer from './networkReducer';
import userDetailsReducer from './userDetailReducer';
import newAppVersionReducer from './newAppVersionReducer';

// Combine all reducers.
const appReducer = combineReducers({
  registration: registrationReducer,
  auth: authReducer,
  user: accountReducer,
  profile: profileReducer,
  MiimProfile: miimProfileReducer,
  quiz: quizReducer,
  errorsStatus: errorsReducer,
  errorMessage: errorMessageReducer,
  fields: fieldsInformationReducer,
  checkboxes: checkboxReducer,
  range: rangeReducer,
  profileGraphQl: profileStoreReducer,
  fileUploaded: FileUploadReducer,
  input: inputChangeReducer,
  loading: loadingReducer,
  message: messageReducer,
  node: nodeReducer,
  toogleCheckbox: checkReducer,
  inputChange: inputReducer,
  riddles: spthinxReducer,
  network: networkReducer,
  userNetwork: userDetailsReducer,
  appVersion: newAppVersionReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'DESTROY_SESSION') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
