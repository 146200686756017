import Cookies from 'js-cookie';
import Validators from './Validators';
import React from 'react';

const Helpers = {
  /**
   * Limit checkbox check options.
   *
   * @param object
   *    The object to check.
   *
   * @param limit
   *     The number of the limit.
   * @param values
   *   The values to exclude.
   *
   * @returns {...*[]}
   */
  checkBoxLimit: (object, limit, values) => {
    if (object.length >= limit) {
      let exist = values.map((item) => (
        object.includes(item)
      ));
      if (exist.includes(true)) return;
      return [...object, ...values];
    }
  },
  /**
   * Slice Object.
   *
   * @param object
   *  The object to slide.
   *
   * @param slice
   *  The position.
   *
   * @returns {{}}
   */
  sliceObject: (object, slice) => Object.keys(object).slice(slice).reduce((result, key) => {
    result[key] = object[key];
    return result;
  }, {}),

  /**
   * Help notifications.
   *
   * @returns {String}
   */
  helpNotification: (text, delay) => {
    return window.M.toast({
      html: text,
      displayLength: delay,
    });
  },
  /**
   *Build  responsive stepper.
   * @param currentPage
   *     The current page.
   *
   * @param pageCount
   *   Number of total pages.
   *
   * @param delta
   * @returns {any[]}
   */
  stepper: (currentPage, pageCount, delta = 1) => {
    const separate = (a, b) => [a, ...({0: [], 1: [b]}[b - a] || ['...', b])];
    return Array(delta * 2 + 1)
      .fill(3)
      .map((_, index) => currentPage - delta + index)
      .filter(page => 0 < page && page <= pageCount)
      .flatMap((page, index, {length}) => {
        if (!index) return separate(1, page);
        if (index === length - 1) return separate(page, pageCount);

        return [page];
      });
  },

  /**
   * Quiz stepper icons.
   *
   * @param step
   *   The question step.
   *
   * @returns {*}
   */
  getStepperIcons: (step, type) => {
    const stepsIcons = {
      1: 'far fa-hand-rock',
      2: 'fas fa-ban',
      3: 'fas fa-glass-cheers',
      4: 'fas fa-utensils',
      5: 'fas fa-lightbulb',
      6: 'fas fa-book-reader',
      7: 'fas fa-hand-point-right',
      8: 'fas fa-glasses',
      9: 'fas fa-user-tie',
      10: 'fas fa-child',
      11: 'fas fa-compass',
      12: 'fas fas fa-cookie-bite',
      13: 'far fa-credit-card',
    };
    const stepsRefineIcons = {
      1: 'far fa-hand-rock',
      2: 'fas fa-ban',
      3: 'fas fa-exchange-alt',
      4: 'fas fa-check-double',
      5: 'fas fa-wave-square',
      6: 'fas fa-stamp',
    };
    if (step && type === 'quiz') return stepsIcons[step];
    if (step && type === 'refine') return stepsRefineIcons[step];
    return stepsIcons;
  },

  getKey: (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  },
  /**
   * Split results object.
   *
   * @param object
   *     The options.
   *
   * @param size
   *      The size to split.
   * @returns {Array}
   */
  chunkObject: (object, size) => {
    let results = [];
    let index = 0;
    let current = 0;
    const keys = Object.keys(object);
    const chunkSize = parseInt(keys.length / size);
    Object.keys(object).forEach(key => {
      if (current === 0)
        results[index] = [];
      results[index].push({[key]: object[key]});
      current++;
      if (current === chunkSize) {
        current = 0;
        index++;
      }
    });
    return results;
  },

  /**
   * Generate full screen stepper.
   *
   * @param StepsNumber
   *     Number if the steps.
   *
   * @returns {Array}
   */
  generateStepper: StepsNumber => {
    let steps = [];
    for (let i = 1; i <= StepsNumber; i++) {
      steps.push(i);
    }
    return steps;
  },

  isCurrentStep: (question, option) => {
    return parseInt(question) === parseInt(option);
  },

  /**
   * Get tooltip.
   *
   * @param data
   *  The tooltip data.
   *
   * @param position
   *  The position.
   *
   *  @param bool
   *
   *
   * @returns {{"data-position": *, "data-html": string, "data-tooltip": *}}
   */
  getToolTip: (data, position, bool) => {
    let tooltip;
    tooltip = {
      'data-position': position,
      'data-html': bool,
      'data-tooltip': data,
    };
    return tooltip;
  },

  /**
   *  Remove after specific  character.
   * @param text
   *      Text to operate.
   *
   * @param length
   *      The length.
   *
   * @returns {string}
   */
  removeTextAfterGivenLength: (text, length) => {
    return text.substr(length, text.indexOf('.'));
  },

  /**
   * Manage Error message status.
   *
   * @param status
   *  String | The status.
   *
   * @returns {string}
   *   Message to display.
   */
  getErrorStatus: (status) => {
    let message;
    if (status === 403) {
      message = 'Access denied';
    } else if (status === 404) {
      message = 'Page not found';
    } else if (status === 401) {
      message = 'Your session has expired';
    } else {
      message = 'Something went wrong';
    }
    return message;
  },
  /**
   * Update body classes.
   */
  stateClassesUpdate: () => {
    document.body.classList.remove('logged_in');
  },

  /**
   * Get Bearer token form LocalStorage (Oauth2).
   *
   * @returns {string}
   */
  getAccessToken: () => {
    const storageOAuth = Cookies.get('oauth');
    return storageOAuth ? `Bearer ${JSON.parse(storageOAuth).access_token}` : null;

  },

  getTokenExpiration: () => {
    const storageOAuth = Cookies.get('oauth');
    return storageOAuth ? JSON.parse(storageOAuth).expires_in : null;
  },

  /**
   * Get refresh token.
   *
   * @returns {string}
   */
  getRefreshToken: () => {
    const storageOAuth = Cookies.get('oauth');
    return storageOAuth ? JSON.parse(storageOAuth).refresh_token : null;
  },

  /**
   * Cred info.
   *
   * @param email
   *  email to login.
   *
   * @param password
   *
   *  The password.
   * @returns {URLSearchParams}
   */
  grantAccess: (email, password) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', email);
    params.append('client_id', '2fd496d1-1f4f-4378-87a4-c562802f8fbe');
    params.append('password', password);
    return params;
  },

  /**
   * Get refreshToken.
   */
  refreshToken: (refresh) => {
    const params = new FormData();
    params.set('grant_type', 'refresh_token');
    params.set('refresh_token', refresh);
    params.set('client_id', '2fd496d1-1f4f-4378-87a4-c562802f8fbe');
    return params;
  },

  /**
   * Get BirthDate for given age.
   *
   * @param limitAge
   *  Given age to calculate.
   *
   * @returns {Date}
   *  Date of birth.
   */
  getBirthDate: limitAge => {
    let today = new Date();
    const calculatedDate =  today.setFullYear( today.getFullYear() - limitAge);
    return new Date(calculatedDate);
  },
  
  dateConvertToYear: date => {
    const d = new Date(parseInt(date) * 1000);
    return d.getFullYear();
  },
  
  formatDate: date => {
    let d = new Date(date * 1000);
    let hours = d.getHours(); // => 9
    let min = d.getMinutes(); // =>  30
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return `${d.toLocaleDateString('en-US', options)   } at ${    Validators.addZero(hours)  }: ${  Validators.addZero(min)}`;
  },
  
  convertDateToUsFormat: date => {
    const d = new Date(parseInt(date) * 1000);
    return d.toLocaleDateString('en-US');
  },
  chatInputLengthValidate: (text, charLength) => {
    if (text.length > charLength) {
      return {
        message: `Your message is too long. Character count: ${text.length} | Max: ${charLength}`,
        error: true,
      };
    }
    else if (text.length <= charLength) {
      return {
        message: '',
        error: false,
      };
    }
  },
  // eslint-disable-next-line react/display-name
  getSmallLoader: type => {
    return(
      <div className={type}>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    );
  },
  cookiesEnabled: () => {
    try {
      document.cookie = 'cookietest=1';
      const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  },
};

export default Helpers;
