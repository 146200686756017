import {PureComponent} from 'react';
import PropTypes from 'prop-types';

class ScrollToTop extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  render = () => {
    if (this.props.children) {
      return this.props.children;
    }

    return '';
  };
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
};

export default ScrollToTop;
