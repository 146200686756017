const initState = {
  changed: {},
};

const inputReducer = (state = initState, action) => {
  const newState = {
    ...state,
  };

  if (action.type === 'INPUT_CHANGED') {
    newState.changed = {
      ...newState.changed,
      ...action.payload,
    };
  }
  return newState;

};

export default inputReducer;
