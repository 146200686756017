import React, {useState, useEffect} from 'react';
import {matching} from './api/matching';
import PropTypes from 'prop-types';
import errorsAction from '../../../../store/actions/commun/errorsAction';
import connect from 'react-redux/es/connect/connect';
import Sidebar from '../sidebar';
import styled from 'styled-components';
import Select from '../../../../components/Select';
import Chart from '../../../../components/helpers/Chart';
import {getUserDetail} from '../api/network';
import Loader from '../../../../components/Loader';
import Validators from '../../../../components/helpers/Validators';

const Match = ({errorMessageDisplay, user, match}) => {
  let array = {};
  let i =0;
  let {uid} = match.params;
  const [data, setData] = useState({});
  const [selectValue, setSelectValue] = useState('general');
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(()=> {
  
    getUserDetail(uid, items => {
      setUserDetails(items);
      setIsLoading(true);
    }, errorMessageDisplay);
    
    matching(uid, item => {
      setData(item);
    }, errorMessageDisplay
    );
  }, []);
  
  const userObj = {
    firstName: userDetails.fname,
    lastName: userDetails.lname,
    avatar: userDetails.avatar,
    uid,
  };

  const StyledTd = styled.td`
    background-size : ${props => `${generateBgSize(parseInt(props.size))  }%` +  ' ' +  '100%'};
  `;
  
  const StyledDiv = styled.div`
    --percentage : ${props => props.percent};
    --fill: ${props => props.percent < 26 ? '#26a69a' : '#ab2171' };  ;
    margin: 2rem auto;
    p {
      span {
        font-size: 2rem;
      }
    }
  `;
  
  const selectOptions = () => {
    return {
      'couple': 'Couple',
      'general' : 'General Match',
      'horizontal' : 'Friends and Colleagues',
      'vertical': 'Hierarchy and Learning',
    };
  };
  
  const handleInput = e => {
    const {value} = e.target;
    setSelectValue(value);
  };
  
  const generateMatchDonutText = percent => {
    let results;
    if (percent > 80) {
      results = 'Excellent';
    }
    else if (percent <= 80 && percent > 70) {
      results = 'Very good';
    }
    else if (percent <= 70 && percent > 60 ) {
      results = 'Good';
    }
    else if (percent <= 60 && percent > 50 ) {
      results = 'Fair';
    }
    else if (percent <= 50 && percent > 40 ) {
      results = 'Weak';
    }
    else if (percent <= 40 ) {
      results = 'Very low';
    }
    
    return results;
  };
  
  const generateBgSize = size => {
    if (size === 1) {
      return 20;
    }
    else if(size === 2) {
      return 35;
    }
    else if (size === 3) {
      return 50;
    }
    else if (size === 4) {
      return 75;
    }
    else if (size === 5) {
      return 100;
    }
    else {
      return 0;
    }
  };
  
  if (Validators.isEmpty(data, true)) return '';
  const getContent = () => {
    return <>
        <div className="section" id="index-banner">
          <div className="miim-standard-top-bg" />
          <div className="container">
            <div className="row">
              <div className="col header_left s12 l3">
              </div>
              <div className="col header_right s12 l9">
                <h1 className="header page_title account_page">
                  Profile Matching
                </h1>
              </div>
            </div>
            <div className="row">
              <Sidebar user={userObj} type="userDetail"/>
              <div className="col right_down m12 l8">
                <div className="box mb_thin">
                  <h3 className="thin">{data.match_chart.title}</h3>
                  <div className="row">
                    <div className="col l12">
                      <StyledDiv className="semi-donut margin" percent={data.match_chart.values[selectValue].score}>
                        <p><span>{`${Math.round(data.match_chart.values[selectValue].score)  }%`}</span></p>
                      </StyledDiv>
                      <p className="center top">{generateMatchDonutText(Math.round(data.match_chart.values[selectValue].score))}</p>
                      <div className="input-field col s12 m6 push-m3">
                        <Select
                          name={'match'}
                          options={selectOptions()}
                          value={selectValue}
                          onChange={handleInput}
                          hideSelectLabel={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box mb_thin">
                  <h3 className="thin">{data.match_text.title}</h3>
                  <div className="row">
                    <div className="col l12">
                      <p>
                        {data.match_text.body}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box mb_thin">
                  <h3 className="thin">{data.match_details.title}</h3>
                  <div className="row">
                    <div className="col l12">
                      <table className="match">
                        <tbody>
                          <tr>
                            <th/>
                            <th className="right">{`${user.firstName  } ${  user.lastName}`} </th>
                            <th>{`${userObj.firstName  } ${  userObj.lastName}`}</th>
                          </tr>
                          {
                            data.match_details.values.map(item => {
                              return(
                                <tr key={`${item.label  }-${  i++}`}>
                                  <td>{item.label}</td>
                                  <StyledTd
                                    className={item.score_id_1 === item.score_id_2 ? 'cellSimilarity' : 'diff' } size={item.score_id_1}>
                                    <span>{item.score_id_1}</span>
                                  </StyledTd>
                                  <StyledTd
                                    className={item.score_id_1 === item.score_id_2 ? 'cellSimilarity' : 'cellDifference'} size={item.score_id_2} >
                                    <span>{item.score_id_2}</span>
                                  </StyledTd>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="box mb_thin">
                  <h3 className="thin">{data.predictions.title}</h3>
                  <div className="row">
                    <div className="col l12">
                      {Object.values(data.predictions.values).map(items => {
                        array[items.label] = items.score;
                      })}
                      <Chart size={150} chartLabels={Object.keys(array)} label={'Predictions'} type={'HorizontalBar'} values={Object.values(array)} />
                    </div>
                  </div>
                </div>
                <div className="box mb_thin">
                  <h3 className="thin">Legend</h3>
                  <div className="row">
                    <div className="col l12">
                      {Object.values(data.predictions.values).map(items => {
                        return(
                          <li key={items.label}><b>{items.label}</b>:  {items.legend}</li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>;
  };
  return isLoading ? getContent() : <Loader />;
  
};

Match.propTypes = {
  data: PropTypes.object,
  errorMessageDisplay: PropTypes.func,
  profile: PropTypes.object,
  user: PropTypes.object,
  percent: PropTypes.number,
  size: PropTypes.number,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state,
    ...errorsAction,
  };
};
export default connect(mapStateToProps, {
  ...errorsAction,
})(Match);

