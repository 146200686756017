import React, {Component} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

class Select extends Component {

  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    title: PropTypes.string,
    value: PropTypes.string,
    hideSelectLabel: PropTypes.bool,
  };
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const elems = document.querySelectorAll('select');
    window.M.FormSelect.init(elems);
  }
  
  componentDidMount = () => {
    const elems = document.querySelectorAll('select');
    window.M.FormSelect.init(elems);
    $(document).click(function(){
      $('li[id^="select-options"]').on('touchend', function (e) {
        e.stopPropagation();
      });
    });
  };

  render() {
    const {props} = this;
    const defaultValue = !props.value || props.value.length === 0 ? '' : props.value;
    return (<>
      <label htmlFor={props.name}> {props.title} </label>
      <select name={props.name} value={defaultValue} onChange={props.onChange}
      >
        {!props.hideSelectLabel ? <option value="">{'Select'}</option> : ''}
        {Object.keys(props.options).map(key => {
          return (
            <option key={key} value={key}> {props.options[key]}</option>
          );
        })}
      </select>
    </>);
  }
}

export default Select;
