const initState = {
  errorMessage: null,
};
const errorMessageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'DATA_FAILURE':
      return {
        ...state,
        errorMessage: action.payload,
      };

    case 'REMOVE_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: null,
      };

    default:
      return state;
  }
};

export default errorMessageReducer;
