import React, {Component} from 'react';

class Loader extends Component {

  componentDidMount = () => {
    const nav = document.querySelector('.navbar-fixed nav');
    if (nav) nav.className = 'scroll';
  };

  componentWillUnmount() {
    const nav = document.querySelector('.navbar-fixed nav');
    if (nav) nav.className = '';
  }

  render = () => {
    return (
      <div className="lmask" />
    );
  };
}

export default Loader;
