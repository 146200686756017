import {config} from '../../config/Config';
import {query, queuedQuery} from './commun/query';
import axios from 'axios';
import {Logout} from './authAction';

export const userAccount = (uid) => dispatch => {
  dispatch({type: 'USER_LOAD_REQUEST'});

  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/user/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: response => {
      dispatch(
        {
          type: 'USER_LOAD_SUCCESS',
          payload: response.data,
        });
    },
    catching: () => {
    },
  });
};

/**
 *  Get user country list.
 *
 * @returns {function(*=): Promise<AxiosResponse<any> | never>}
 */
export const countryList = () => dispatch => {

  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/countries?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: (res) => {
      dispatch(
        {
          type: 'USER_COUNTRY_LIST',
          payload: res.data,
        });
    },
    catching: (error) => {
      dispatch(
        {
          type: `ERROR_${error.response.status}`,
          payload: error.response.status,
        });
    },
  });
};

export const userInputChange = (name, value) => dispatch => {
  dispatch({'type': 'USER EDIT', name, value});
};

/**
 * Delete user.
 *
 * @param uid
 *   The user uid.
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteUser = (uid) => dispatch => {
  dispatch({type: 'DISPLAY_MESSAGE_REQUEST'});

  queuedQuery({
    request: {
      method: 'delete',
      url: `${config.BASE_PATH}/user/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: () => {
      Logout();
      window.location = '/';
    },
    catching: error => {
      dispatch(
        {
          type: 'DATA_FAILURE',
          payload: error.response,
        });
    },
  });
};

/**
 * Update user.
 *
 * @param data
 *  User info.
 *
 * @param state
 *  Fields to change.
 *
 * @returns {Function}
 */
export const updateUser = (data, state) => dispatch => {
  dispatch({type: 'USER_EDIT_REQUEST'});

  let userData = data;
  userData.field_miim_user_fname[0].value = state.firstName;
  userData.field_miim_user_lname[0].value = state.lastName;

  query({
    method: 'patch',
    url: `/user/${data.uid[0].value}?_format=json`,
    headers: {'content-type': 'application/json'},
    data: userData,
    then: () => {
      dispatch({type: 'DISPLAY_MESSAGE_SUCCESS', payload: 'The changes have been saved'});
    },
    catching: error => {
      dispatch(
        {
          type: 'DATA_FAILURE',
          payload: error.response.data.message,
        });
    },
  });
};

export const resetPasswordAction = (password, hash, history) => dispatch => {
  dispatch({type: 'RESET_PASSWORD_REQUEST'});

  axios.get(`${config.BASE_PATH}/session/token`)
    .then(tokenResponse => {
      axios.put(
        `${config.BASE_PATH}/miim/reset-password/${hash}?_format=json`,
        {
          password,
        },
        {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': tokenResponse.data,
          },
        })

        .then(res => {
          history.push('/login');
          dispatch({type: 'DISPLAY_MESSAGE_SUCCESS', payload: res.data.message});
        })
        .catch(error => {
          dispatch({type: 'DATA_FAILURE', payload: 'error.response.data.message'});
        });
    });
};

export const resetPasswordRequestAction = (email, history) => dispatch => {
  dispatch({type: 'RESET_PASSWORD_REQUEST'});

  axios.get(`${config.BASE_PATH}/session/token`)
    .then(tokenResponse => {
      axios.post(
        `${config.BASE_PATH}/miim/reset-password?_format=json`,
        {
          email,
        },
        {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': tokenResponse.data,
          },
        })

        .then(res => {
          history.push('/login');
          dispatch({type: 'DISPLAY_MESSAGE_SUCCESS', payload: res.data.message});
        })
        .catch(error => {
          dispatch({type: 'DATA_FAILURE', payload: error.response.data.message});
        });
    });
};

