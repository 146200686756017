import axios from 'axios';
import {config} from '../../config/Config';
import {query} from './commun/query';
import Cookies from 'js-cookie';

export const emailConfirmation = (hash, history) => dispatch => {
  axios.get(`${config.BASE_PATH}/session/token`)
    .then(tokenResponse => {
      axios.post(
        `${config.BASE_PATH}/miim/confirmation`,
        {
          'confirmation': hash,
        },
        {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': tokenResponse.data,
          },
        })

        .then(res => {
          history.push('/login');
          dispatch({type: 'DISPLAY_MESSAGE_SUCCESS', payload: 'Your email has been validated.'});
        })
        .catch(error => {
          dispatch({type: 'DATA_FAILURE', payload: error.response.data.error});
        });
    });
};

export const getCurrentUser = dispatch => {
  return axios.get(`${config.BASE_PATH}/miim/me?_format=json`,
    {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(res => {
      dispatch({
        type: 'USER_ID',
        payload: res.data.user_id,
      });
    }).catch(error => {
      dispatch(
        {
          type: 'DATA_FAILURE',
          payload: error.response.data.message,
        });
      Cookies.remove('state', { path: '' });
      Cookies.remove('oauth', { path: '' });
      localStorage.clear();
    });
};

export const LogInOauth = (credentials, persistStorage) => dispatch => {
  dispatch({type: 'LOGIN_REQUEST'});
  query({
    method: 'post',
    url: '/oauth/token',
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    data: credentials,
    noRefresh: true,
    then: res => {
      if (persistStorage) {
        localStorage.setItem('rememberUser', true);
      }
      localStorage.setItem('lastRequestSession', (new Date()).getTime());
      const options = persistStorage ? {expires: 14} : {};
      Cookies.set('oauth', res.data, { ...options, sameSite: 'Lax' });
      dispatch(
        {
          type: 'LOGIN_SUCCESS',
          payload: res.data,
        });
      getCurrentUser(dispatch);
    },
    catching: error => {
      dispatch(
        {
          type: 'DATA_FAILURE',
          payload: error.response.data.message,
        });
    },
  });
};

export const Logout = ()  => {
  document.cookie.split(';')
    .forEach(function(c) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/,
        `=;expires=${  new Date().toUTCString()  };path=/`); });
  localStorage.clear();
};
