import {Redirect, Route} from 'react-router-dom';
import React from 'react';
import ErrorPage from '../layout/ErrorPage';
import connect from 'react-redux/es/connect/connect';

class ProtectedRoutes extends Route {
  render() {
    const {accessCallback, redirect, location, accessStatus} = this.props;
    if (accessStatus) {
      return (<ErrorPage errorStatus={accessStatus} />);
    }
    if (accessCallback) {
      return super.render();
    }
    else if (redirect) {
      return <Redirect to={{pathname: redirect, state: location}} />;
    }
    else {
      return <div className="lmask" />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(ProtectedRoutes);
