import axios from 'axios';
import {config} from '../../../config/Config';

/**
 * Global query.
 */
export const query = ({method, url, headers, data, then, catching}) => {
  let promise;
  const queryUrl = config.BASE_PATH + url;
  const options = {
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
  };
  if (method === 'get') {
    promise = axios.get(queryUrl, options);
  } else {
    promise = axios[method](queryUrl, data, options);
  }
  promise
    .then(then)
    .catch(catching);

};

const queries = [];
let busyQuering = false;

export const queuedQuery = (requestObject) => {
  queries.push(requestObject);
};

window.setInterval(async () => {
  if (!busyQuering) {
    busyQuering = true;
    const requestObject = queries.pop();
    if (requestObject) {
      const {request, then, catching} = requestObject;

      try {
        const response = await axios(request);
        then(response);
      } catch (error) {
        catching(error);
      }
    }
    busyQuering = false;
  }
}, 1);
