import {queuedQuery} from '../../../../store/actions/commun/query';
import {config} from '../../../../config/Config';

export const network = (params, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'post',
      url: `${config.BASE_PATH}/miim/network/user-search?_format=json`,
      headers: {'content-type': 'application/json'},
      data: params,
    },
    then: ({data}) => { 
      callback(data);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getUserDetail = (uid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/network/profile/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: ({data}) => {
      callback(data);
    },
    catching: (error) => {
      callback(error.response.status);
    },
  });
};

export const getProfile = (uid, callback, errorHandler)  => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        query: `
                query ($id: String!){
                  userById(id: $id) {
                    nom: name
                    profileLinkedToThisUser: reverseUidProfile {
                      listProfiles: entities {
                        ... on ProfileMiimUser {
                          city: fieldMiimUserCity,
                          seekingFor: fieldMiimUserSeeking,
                          seekingMessage: fieldMiimUserSeekingMessage,
                        }
                      }
                    }
                  }
                }
            `,
        variables: {id: uid},
      },
    },
    then: ({data : {data : {userById : {profileLinkedToThisUser: {listProfiles}}}}}) => {
      callback(listProfiles);
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getKnack = (uid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/profile/knack/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: ({data}) => {
      callback(data);
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getReliability= (uid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/profile/reliability/${uid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: ({data}) => {
      callback(data);
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getGeneral= (target, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/match/general/${target}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: ({data}) => {
      callback(data);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getHelpOut = (uid, callback, errorHandler)  => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        query: `
          query($id:[String]){
             networkPostQuery(filter: {conditions: [{field: "user_id", value: $id}]}) {
                ... on EntityQueryResult {
                  count
                }
            }
          }
        `,
        variables: {id: uid},
      },
    },
    then: ({data: {data: {networkPostQuery : {count}}}}) => {
      callback(count);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getConnexion = (uid, callback, errorHandler)  => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        query: `
          query($id:[String]){
             connectionQuery(filter: {conditions: [{field: "field_user_1", value: $id}]}) {
                ... on EntityQueryResult {
                  count
                }
            }
          }
        `,
        variables: {id: uid},
      },
    },
    then: ({data: {data: {connectionQuery : {count}}}}) => {
      callback(count);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getConnexionRequest = (uid, callback, errorHandler)  => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        query: `
          query($id:[String]){
             connectionRequestQuery(filter: {conditions: [{field: "field_targeted_user", value: $id}]}) {
                ... on EntityQueryResult {
                  count
                }
            }
          }
        `,
        variables: {id: uid},
      },
    },
    then: ({data: {data: {connectionRequestQuery : {count}}}}) => {
      callback(count);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

const queryVersion = 'miim_network';

export const sendConnectionRequest = (targeted_user, message, callback, errorHandler) => {
  const variables = {
    input: {
      field_targeted_user: targeted_user,
      field_message: message,
    },
  };
  
  const id = 'connection-request';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: ({data: {data: {createConnectionRequest: {entity}}}}) => {
      callback(entity);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getConnectionStatus = (target_user, author_id, callback, errorHandler) => {
  const variables = {
    target_user,
    author_id,
  };
  
  const id = 'connection-info';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: ({data: {data: {connectionRequestQuery: entities}}}) => {
      callback(entities);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getConnectionReceived = (target_user, status, callback, errorHandler) => {
  const variables = {
    target_user,
    status,
  };
  
  const id = 'connection-received';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: ({data: {data: {connectionRequestQuery: entities}}}) => {
      callback(entities);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const acceptRequest = (cid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/network/accept-connection/${cid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: (data) => {
      callback(data);
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const declineRequest = (cid, callback, errorHandler) => {
  queuedQuery({
    request: {
      method: 'get',
      url: `${config.BASE_PATH}/miim/network/decline-connection/${cid}?_format=json`,
      headers: {'content-type': 'application/json'},
    },
    then: (data) => {
      callback(data);
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};
export const getConnectionList = (uid, callback, errorHandler) => {
  const variables = {
    id: uid,
  };
  
  const id = 'connection-list';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: ({data: {data: {connectionQuery: entities}}}) => {
      callback(entities);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const sendPost = (uid, message, callback, errorHandler) => {
  const variables = {
    input: {
      field_network_post_to: uid,
      field_network_post_message: message,
    },
  };
  
  const id = 'send-post';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: (data) => {
      callback(!!data.status);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const getUnreadPost = (target_user, read, callback, errorHandler) => {
  const variables = {
    read,
    target_user,
  };
  const id = 'last-unread-post';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    
    then: ({data: {data: {networkPostQuery: entities}}}) => {
      callback(entities);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const markAsRead = (pid, callback, errorHandler) => {
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/miim/post/mark-read/${pid}`,
      method: 'patch',
      data: {},
    },
    then: () => {
      callback();
    },
    catching: () => {
      errorHandler('\'An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};

export const postedToProfile = (target_user, read, offset, limit, callback, errorHandler) => {
  const variables = {
    read,
    target_user,
    offset,
    limit,
  };
  const id = 'public-posts';
  queuedQuery({
    request: {
      url: `${config.BASE_PATH}/graphql`,
      method: 'post',
      data: {
        queryId: `${queryVersion}:${id}`,
        variables,
      },
    },
    then: ({data: {data: {networkPostQuery: entities}}}) => {
      callback(entities);
    },
    catching: () => {
      errorHandler('An error occurred. Make sure that you are connected. If the error still occurs please contact the helpdesk.');
    },
  });
};
