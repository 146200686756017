const initState = {
  dataProfile: {},
  dataUser: {},
};
const fieldsInformationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FIELDS INFO.profile_SUCCESS':
      return {
        ...state,
        dataProfile: action.payload,
      };
    case 'FIELDS INFO.user_SUCCESS':
      return {
        ...state,
        dataUser: action.payload,
      };
    default:
      return state;
  }
};

export default fieldsInformationReducer;
