const initState = {
  countries: {},
};
const accountReducer = (state = initState, action) => {
  switch (action.type) {
    case 'USER_LOAD_SUCCESS':
      return {
        ...state,
        data: action.payload,
        firstName: action.payload.field_miim_user_fname[0].value,
        lastName: action.payload.field_miim_user_lname[0].value,
        uid: action.payload.uid[0].value,
        name: action.payload.name[0].value,
        mail: action.payload.mail[0].value,
        avatar: action.payload.user_picture.length > 0 ? action.payload.user_picture[0].url : '',
        message: null,
      };
    case 'USER EDIT':
      return {
        ...state,
        [action.name]: action.value,
        message: null,
      };
    case 'USER_COUNTRY_LIST':
      return {
        ...state,
        countries: action.payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
