const initState = {
  'password': '',
  'email': '',
};
const inputChange = (state = initState, action) => {
  switch (action.type) {
    case 'INPUT_CHANGE':
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};
export default inputChange;
