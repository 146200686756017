import React, {Component, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './styles/App.scss';

import axios from 'axios';
import ProtectedRoutes from './container/routePermissions/ProtectedRoutes';
import connect from 'react-redux/es/connect/connect';
import Validators from './components/helpers/Validators';
import Loader from './components/Loader';
import ResetPassword from './container/account/ResetPassword';
import ResetPasswordRequest from './container/account/ResetPasswordRequest';
import Match from './container/layout/network/matching/Match';

const NavBar = lazy(() => import('./container/layout/navBar/NavBar'));
const Register = lazy(() => import('./container/auth/Register'));
const LogIn = lazy(() => import('./container/auth/Login'));
const Footer = lazy(() => import('./container/layout/Footer'));
const UserAccount = lazy(() => import('./container/account/UserAccount'));
const Page = lazy(() => import('./container/layout/Page'));
const QuizPage = lazy(() => import('./container/quiz/QuizPage'));
const Profile = lazy(() => import('./container/profile/Profile'));
const ErrorPage = lazy(() => import('./container/layout/ErrorPage'));
const Node = lazy(() => import('./container/layout/Node'));
const Upgrade = lazy(() => import('./container/layout/Upgrade'));
const Spthinx = lazy(() => import('./container/profile/Spthinx'));
const Network = lazy(() => import('./container/layout/network'));
const Messages = lazy(() => import('./container/layout/network/messages'));
const Search = lazy(()=>import('./container/layout/network/search'));
const UserProfile = lazy(()=>import('./container/layout/network/userDetail'));
const QuizRedo = lazy(() =>import('./container/quiz/QuizRedo'));
const QuizRedoBack = lazy(() =>import('./container/quiz/QuizRedoBack'));

axios.defaults.withCredentials = true;

class App extends Component {
  newAppVersion = () => {
    const reloadPageText = '<p>There is a new version of the app. <a onclick="window.location.reload(true); e.preventDefault()" href="#">Reload the page</a> to update.</p>';
    return (
      <div className={'row notification-block update'}>
        <div  className="col s12">
          <p dangerouslySetInnerHTML={{__html: reloadPageText}} className={'center-align'}/>
        </div>
      </div>
    );
  }
  render() {
    const {MiimProfile, auth, errorsStatus, isPremium, riddlesExist, usersConnected, redoQuiz, appVersion} = this.props;
    const {newVersion} = appVersion;
    return (
      <>
        {newVersion ? this.newAppVersion() : ''}
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <div className="App">
              <NavBar />
              <main className={'animate-in'}>
                <Switch>
                  <Route
                    render={props => <Page {...props} />}
                    path="/confirmation/:hash"
                  />
                  <ProtectedRoutes
                    accessCallback={!auth.isAuthenticated}
                    render={props => <ResetPassword {...props} />}
                    path='/reset-password/:reset_hash'
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!auth.isAuthenticated}
                    render={props => <ResetPasswordRequest {...props} />}
                    path='/reset-password'
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    render={props => <Page {...props} />}
                    accessCallback={!auth.isAuthenticated}
                    path='/'
                    redirect={'profile'}
                    exact={true}
                  />
                  <ProtectedRoutes
                    render={props => <Register {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={!auth.isAuthenticated}
                    path="/register"
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    render={props => <LogIn {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={!auth.isAuthenticated}
                    path="/login"
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    render={props => <UserAccount {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={auth.isAuthenticated}
                    path="/user/:uid"
                    redirect={'/login'}
                  />
                  <ProtectedRoutes
                    render={props => <QuizPage {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={!MiimProfile.profile}
                    path="/questions"
                    redirect={'/profile'}
                  />
                  <ProtectedRoutes
                    render={props => <Profile {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={MiimProfile.profile}
                    path="/profile"
                    redirect={'/questions'}
                  />
                  <ProtectedRoutes
                    render={props => <QuizRedo {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={auth.isAuthenticated && redoQuiz}
                    path="/questions-redo"
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    render={props => <QuizRedoBack {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={auth.isAuthenticated && redoQuiz}
                    path="/questions-redo-back"
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    render={props => <Upgrade {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={!isPremium && auth.isAuthenticated}
                    path="/upgrade"
                    redirect={'/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated}
                    accessStatus={errorsStatus.status}
                    render={props => <Network {...props} />}
                    path="/network"
                    redirect={isPremium === undefined ? undefined : '/'}
                    exact={true}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated}
                    accessStatus={errorsStatus.status}
                    render={props => <Search {...props} />}
                    exact={true}
                    path="/network/search"
                    redirect={isPremium === undefined ? undefined : '/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated}
                    accessStatus={errorsStatus.status}
                    render={props => <UserProfile {...props} />}
                    exact={true}
                    path="/network/profile/:uid"
                    redirect={isPremium === undefined ? undefined : '/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated && usersConnected}
                    accessStatus={errorsStatus.status}
                    render={props => <Match {...props} />}
                    exact={true}
                    path="/network/matching/:uid"
                    redirect={isPremium === undefined ? undefined : '/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated}
                    accessStatus={errorsStatus.status}
                    render={props => <Messages {...props} />}
                    path="/network/messages"
                    redirect={isPremium === undefined ? undefined : '/'}
                  />
                  <ProtectedRoutes
                    accessCallback={!!isPremium && auth.isAuthenticated}
                    accessStatus={errorsStatus.status}
                    render={props => <Search {...props} />}
                    path="/network/connections"
                    redirect={isPremium === undefined ? undefined : '/'}
                  />
                  <ProtectedRoutes
                    render={props => <QuizPage {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={isPremium}
                    path="/refine"
                    redirect={'/upgrade'}
                  />
                  <ProtectedRoutes
                    render={props => <Spthinx {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={riddlesExist && isPremium}
                    path="/spthinx/:type"
                    redirect={'/upgrade'}
                  />
                  <ProtectedRoutes
                    render={props => <Node {...props} />}
                    accessStatus={errorsStatus.status}
                    accessCallback={true}
                    path="/:alias"
                  />
                  <Route render={props => <ErrorPage {...props} />} />
                </Switch>
              </main>
              <Footer />
            </div>
          </Suspense>
        </BrowserRouter>
      </>
    );
  }
}

App.propTypes = {
  MiimProfile: PropTypes.object,
  auth: PropTypes.object,
  errorsStatus: PropTypes.object,
  profile: PropTypes.object,
  isPremium: PropTypes.bool,
  profileExist: PropTypes.bool,
  riddlesExist: PropTypes.bool,
  usersConnected: PropTypes.bool,
  redoQuiz: PropTypes.bool,
  appVersion: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state,
    isPremium: state.profile.premium,
    profileExist: state.MiimProfile.status === 200,
    riddlesExist: !Validators.isEmpty(state.riddles.data, true),
    usersConnected: !Validators.isEmpty(state.network.data) ? state.network.data.usersConnected : false,
    redoQuiz: state.quiz.redoQuiz,
  };
};
export default connect(mapStateToProps)(App);
