const initState = {
  privacy: [],
  notifications: [],
  sendRequest: [],
};
const profileReducer = (state = initState, action) => {

  switch (action.type) {
    case 'PROFILE_LOAD_SUCCESS': {
      let profile = action.payload.data.userById.profileLinkedToThisUser.listProfiles[0];
      return {
        ...state,
        data: initState,
        address: profile.address,
        city: profile.city,
        aptSuite: profile.aptSuite,
        zipCode: profile.zipCode,
        state: profile.state,
        country: profile.country,
        profileId: profile.profileId,
        phone: profile.phone,
        maritalStatus: profile.maritalStatus,
        workSituation: profile.workSituation,
        seekingMessage: profile.seekingMessage,
        seekingFor: profile.seekingFor,
        privacy: profile.privacy,
        notifications: profile.notifications,
        sendRequest: profile.sendRequest,
        count: action.payload.data.userById.profileLinkedToThisUser.listProfiles,
        premium: profile.premium,
      };
    }
    case 'PROFILE_LOAD_FAILURE':
      return {
        ...state,
      };
    case 'PROFILE EDIT':
      return {
        ...state,
        [action.name]: action.value,
      };

    case 'UPDATE ERROR':
      return {
        ...state,
        errorMessage: action.error,
      };
    case 'PROFILE CHECKED':
      return {
        ...state,
        [action.name]: [
          ...state[action.name],
          action.value,
        ],
      };
    case 'PROFILE UNCHECKED':
      return {
        ...state,
        [action.name]: state[action.name].filter(item => item !== action.value),
      };

    case 'RADIO SELECTED':
      return {
        ...state,
        sendRequest: action.value,
      };
    default:
      return state;
  }
};

export default profileReducer;
