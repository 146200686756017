import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {

  componentDidMount() {
    const {id} = this.props;
    const elem = document.getElementById(id);
    const {Modal} = window.M;
    const instance = Modal.init(elem, {
      dismissible: false,
    });
    instance.open();
  }

  render() {
    const {id, content, footer, className} = {className: [], ...this.props};
    return (
      <div id={id} className={['modal', ...className].join(' ')}>
        <div className="modal-content">
          {content}
        </div>
        {
          footer ? (
            <div className="modal-footer">
              {footer}
            </div>
          ) : ''
        }
      </div>
    );
  }
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
  className: PropTypes.array,
};

export default Modal;

export const closeModal = id => {
  const elem = document.getElementById(id);
  const {Modal} = window.M;
  const instance = Modal.init(elem, {
    dismissible: false,
  });
  instance.close();
  document.body.style.overflow = 'visible';
};
