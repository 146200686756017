const initState = {
  refineQuizDone: false,
  redoQuiz: false,
  resetRedoQuiz: false,
};
const quizReducer = (state = initState, action) => {
  switch (action.type) {
    case 'DISPLAY_QUIZ_SUCCESS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'SUBMIT_QUIZ_SUCCESS':
      if (action.payload.status && action.payload.status === 'OK') {
        return {
          ...state,
          quizDone: true,
        };
      } else {
        return {
          ...state,
          questions: action.payload,
        };
      }
    case 'DISPLAY_QUIZ_ERROR':
      return {
        ...state,
        done: true,
      };
    case 'QUIZ_ERROR_RESET':
      return {
        ...state,
        quizDone: false,
        refineQuizDone: false,
        empty: false,
      };
    case 'DISPLAY_REFINE_SUCCESS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'SUBMIT_REFINE_SUCCESS':
      if (action.payload.status && action.payload.status === 'OK') {
        return {
          ...state,
          refineQuizDone: true,
          quizDone: true,
        };
      } else {
        return {
          ...state,
          questions: action.payload,
        };
      }
    case 'QUIZ_EMPTY':
      return {
        ...state,
        empty: true,
      };
    case 'REDO_QUIZ':
      return {
        ...state,
        redoQuiz: action.payload,
      };
    case 'RESET_REDO_QUIZ':
      return {
        ...state,
        resetRedoQuiz: true,
      };
    case 'REDO_QUIZ_DONE':
      return {
        ...state,
        resetRedoQuiz: false,
      };

    default:
      return state;
  }
};

export default quizReducer;
